import { Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/home/home";

import "../node_modules/@in/component-library/dist/component-library.css";
import CookieList from "./components/cookieList/cookieList";

const App = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="cookies" element={<CookieList />} />
  </Routes>
);

export default App;
